.ant-layout{
    height:auto !important;
}

.site-layout-background{
    height: 100vh;
}

.ant-menu-item-selected{
    background-color: #001529 !important;
}

.ant-layout-header{
	line-height: initial;
}

header{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

header .ant-menu{
	width: 100%;
}

header .ant-menu-item{
	height: 64px;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

header .ant-menu-item span{
	display: block;
}

header .user-detail{
	width: 150px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

header .user-detail h4, header .user-detail h5{
	color: #f6f6f6;
	margin-bottom: 0;
}

header .user-detail .anticon{
	color: #f6f6f6;
	font-size: 26px;
	margin-right: 10px;
}