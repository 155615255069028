.dark-bg{
	background-color: rgba(0,0,0,.6);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	opacity: 0;
	transition: opacity .5s ease;
}

.side-container{
	background-color: #fff;
	position: fixed;
	top: 0;
	height: 100%;
	right: 0;
	z-index: 1;
	transition: all .7s ease;
	width: 0;
	display: none;
}

.side-container .close{
	float: right;
	width: 27px;
	height: 28px;
	background: rgb(204, 204, 204, 50%);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	border-radius: 50%;
	font-weight: 500;
	color: #fff;
}

.side-container .close:hover{
	background: rgb(75, 75, 75, 30%);
}

.side-container form{
	padding: 50px;
}

.side-container .form-group{
	display: flex;
	margin-bottom: 8px;
}

.side-container input, .side-container select, .side-container button{
	width: 260px;
	border: 1.5px solid #8d8d8d;
	border-radius: 5px;
	margin: 4px;
}

.side-container select{
	text-align: center;
}

.side-container button{
	text-align: center;
	background-color: #001529;
	border: 0;
	color: #fff;
	font-weight: 500;
	height: 34px;
	width: 100%;
}

.side-container button:hover, .side-container button:focus{
	background-color: #001529;
}

.side-container input::placeholder {
	color: rgba(0, 0, 0, 0.65);
	opacity: 1;
}

.side-container .full-width input{
	width: 100%;
}