 
 h2{
    font-size: 30px !important;
}


.login{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #001529;
    transition: 0.5s;
}

.container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 800px;
    height: 500px;
    margin:20px;
}

.logo-login{
    background: rgba(255,255,255,0.4);
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
}

.logo-login h3{
    font-size: 20px;
    margin-bottom: 0;
    color: #fff;
    text-align: center;
}


.blueBg{
    position: absolute;
    top: 40px;
    width: 100%;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.2);
    box-shadow: 0 5px 45px rgba(0,0,0,0.15) ;
}

.blueBg .box {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blueBg .box h2 {
    color: #fff;
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 10px;
}

.blueBg .box button {
    cursor:pointer;
    padding: 10px 20px;
    background: #fff;
    color:#333;
    font-size: 16px;
    font-weight: 500;
}

.contForm {
    width: 50%;
}

.formBx {
    width: 100%;
    background: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 45px rgba(0,0,0,0.25);
    transition: 0.5s ease-in-out;
    border-radius: 0px 0px 5px 5px;
}

.formBx.active{
    left: 50%;

}

.formBx .form {
    width: 100%;
    padding: 50px;
    transition: 0.5s;
}

.formBx .form form {
    width:100%;
    display: flex;
    flex-direction: column;
}

.formBx .form form h3{
    font-size: 1.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: 500;
}

.formBx .form form input {
    width: 100%;
    margin-bottom:20px;
    padding: 10px ;
    outline: none;
    font-size: 16px;
    border: 1px solid #2b2b2b;
}

.formBx .form form input[type="submit"]{
    background: #001529;
    border: none;
    color:#fff;
    cursor: pointer;
    margin-bottom: 0;
}