@import '~antd/dist/antd.css';
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f0f2f5;
}
.App {
  text-align: center;
}

.ant-collapse-header{
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  letter-spacing: 1px;
}

.ant-collapse-item{
  transition: 0.5s ease-in;
}

.ant-collapse-item:hover{
  background-color: #f0f2f5 ;
}

.ant-collapse-item-active{
  background-color: #f0f2f5 ;
}

.ant-collapse-content{
  background-color: white !important;
}

.ant-collapse-content-box{
  padding: 40px !important;
}

.medicamentoDisabled>.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: white !important;
  
}

.medicamentoDisabled.ant-input-disabled{
  background-color: white !important;
}

.medicamentoDisabled.ant-picker.ant-picker-disabled{
  background-color: white !important;
}

.ant-form-item-label{
  white-space: normal;
}

.ant-form-item-label label{
  height: auto;
  padding: 5px 0;
}

.ant-form-item-label label::after{
  align-self: flex-end;
}

.ant-form-horizontal .ant-form-item-control{
  margin: auto;
}