.UsuariosHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

/*
h1 {
    font-size: 30px;
}
*/
