.icon{
    font-size: 1.5em;
    color: black;
    line-height: 1px;
}

.column-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-header {
    text-align: center !important;
}