.dashboard .center{
	display: flex;
	align-items: center;
	justify-content: center;
}

.dashboard .cards-container{
	display: flex;
	justify-content: center;
	align-items: center;
}

.dashboard .cards-container .card{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	padding: 20px 20px;
	border-radius: 5px;
	box-shadow: 1px 1px 4px #ccc;
	margin: 0 10px;
}

.dashboard .cards-container .card .anticon{
	font-size: 40px;
	margin-right: 10px;
}

.dashboard .cards-container .card h4{
	margin-bottom: -6px;
	font-size: 20px;
}

.dashboard .cards-container .card .anticon{
	font-size: 32px;
	color: #75b2c4;
}

.dashboard .charts{
	padding: 40px 0;
}

.dashboard .charts .chart{
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
}

.dashboard .charts .chart-group{
	display: flex;
	margin-bottom: 20px;
}

.dashboard .charts .chart-group .general{
	width: 70%;
	margin-right: 2%;
}

.dashboard .charts .chart-group .gender{
	width: 28%;
}

.dashboard .charts .chart-group .weight{
	width: 100%;
}

.dashboard .charts .chart-group .weight .chart-cont{
	height: 350px;
}

.dashboard .charts .chart-group .weight .pie-chart{
	width: 30%;
	margin-right: 2%;
}

.dashboard .charts .chart-group .weight .scatter-chart{
	width: 68%;
}

.dashboard .charts .chart-group .hospitalizations{
	width: 68%;
}

.dashboard .charts .chart-group .comparative{
	width: 30%;
	margin-right: 2%;
}

.dashboard .charts .chart-group .comparative .radar-chart{
	width: 100%;
	height: 350px;
}


