.Header{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.Header h1 {
    font-size: 30px;
}

.button {
    margin-right:1em;
}